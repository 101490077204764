// Copyright 2021 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import {Button, Form, Input, Result} from "antd";
import * as Setting from "../Setting";
import * as AuthBackend from "./AuthBackend";
import * as ProviderButton from "./ProviderButton";
import i18next from "i18next";
import * as Util from "./Util";
import {authConfig} from "./Auth";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import * as AgreementModal from "../common/modal/AgreementModal";
import {SendCodeInput} from "../common/SendCodeInput";
import RegionSelect from "../common/select/RegionSelect";
import CustomGithubCorner from "../common/CustomGithubCorner";
// import LanguageSelect from "../common/select/LanguageSelect";
import {withRouter} from "react-router-dom";
import {CountryCodeSelect} from "../common/select/CountryCodeSelect";
import * as PasswordChecker from "../common/PasswordChecker";
import * as InvitationBackend from "../backend/InvitationBackend";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      applicationName:
        props.applicationName ?? props.match?.params?.applicationName ?? null,
      email: "",
      phone: "",
      emailOrPhoneMode: "",
      countryCode: "",
      emailCode: "",
      phoneCode: "",
      validEmail: false,
      validPhone: false,
      region: "",
      isTermsOfUseVisible: false,
      termsOfUseContent: "",
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const oAuthParams = Util.getOAuthGetParameters();
    if (oAuthParams !== null) {
      const signinUrl = window.location.pathname.replace(
        "/signup/oauth/authorize",
        "/login/oauth/authorize"
      );
      sessionStorage.setItem("signinUrl", signinUrl + window.location.search);
    }

    if (this.getApplicationObj() === undefined) {
      if (this.state.applicationName !== null) {
        this.getApplication(this.state.applicationName);

        const sp = new URLSearchParams(window.location.search);
        if (sp.has("invitationCode")) {
          const invitationCode = sp.get("invitationCode");
          this.setState({invitationCode: invitationCode});
          if (invitationCode !== "") {
            this.getInvitationCodeInfo(
              invitationCode,
              "admin/" + this.state.applicationName
            );
          }
        }
      } else if (oAuthParams !== null) {
        this.getApplicationLogin(oAuthParams);
      } else {
        Setting.showMessage(
          "error",
          `Unknown application name: ${this.state.applicationName}`
        );
        this.onUpdateApplication(null);
      }
    }
  }

  getApplication(applicationName) {
    if (applicationName === undefined) {
      return;
    }

    ApplicationBackend.getApplication("admin", applicationName).then((res) => {
      if (res.status === "error") {
        Setting.showMessage("error", res.msg);
        return;
      }

      this.onUpdateApplication(res.data);
    });
  }

  getApplicationLogin(oAuthParams) {
    AuthBackend.getApplicationLogin(oAuthParams).then((res) => {
      if (res.status === "ok") {
        const application = res.data;
        this.onUpdateApplication(application);
      } else {
        this.onUpdateApplication(null);
        this.setState({
          msg: res.msg,
        });
      }
    });
  }

  getInvitationCodeInfo(invitationCode, application) {
    InvitationBackend.getInvitationCodeInfo(invitationCode, application).then(
      (res) => {
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }
        this.setState({invitation: res.data});
      }
    );
  }

  getResultPath(application, signupParams) {
    if (signupParams?.plan && signupParams?.pricing) {
      // the prompt page needs the user to be signed in, so for paid-user sign up, just go to buy-plan page
      return `/buy-plan/${application.organization}/${signupParams?.pricing}?user=${signupParams.username}&plan=${signupParams.plan}`;
    }
    if (authConfig.appName === application.name) {
      return "/result";
    } else {
      if (Setting.hasPromptPage(application)) {
        return `/prompt/${application.name}`;
      } else {
        return `/result/${application.name}`;
      }
    }
  }

  getApplicationObj() {
    return this.props.application;
  }

  onUpdateAccount(account) {
    this.props.onUpdateAccount(account);
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
  }

  parseOffset(offset) {
    if (
      offset === 2 ||
      offset === 4 ||
      Setting.inIframe() ||
      Setting.isMobile()
    ) {
      return "0 auto";
    }
    if (offset === 1) {
      return "0 10%";
    }
    if (offset === 3) {
      return "0 60%";
    }
  }

  onFinish(values) {
    const application = this.getApplicationObj();
    const params = new URLSearchParams(window.location.search);
    values.plan = params.get("plan");
    values.username = values.username ?? values.email ;
    values.pricing = params.get("pricing");
    AuthBackend.signup(values).then((res) => {
      if (res.status === "ok") {
        // the user's id will be returned by `signup()`, if user signup by phone, the `username` in `values` is undefined.
        values.username = res.data.split("/")[1];
        if (
          Setting.hasPromptPage(application) &&
          (!values.plan || !values.pricing)
        ) {
          AuthBackend.getAccount("").then((res) => {
            let account = null;
            if (res.status === "ok") {
              account = res.data;
              account.organization = res.data2;

              this.onUpdateAccount(account);
              Setting.goToLinkSoft(
                this,
                this.getResultPath(application, values)
              );
            } else {
              Setting.showMessage(
                "error",
                `${i18next.t("application:Failed to sign in")}: ${res.msg}`
              );
            }
          });
        } else {
          Setting.goToLinkSoft(this, this.getResultPath(application, values));
        }
      } else {
        Setting.showMessage("error", res.msg);
      }
    });
  }

  onFinishFailed(values, errorFields, outOfDate) {
    this.form.current.scrollToField(errorFields[0].name);
  }

  isProviderVisible(providerItem) {
    return Setting.isProviderVisibleForSignUp(providerItem);
  }

  renderFormItem(application, signupItem) {
    if (!signupItem.visible) {
      return null;
    }

    const required = signupItem.required;

    if (signupItem.name === "Username") {
      let emailOrPhoneMode = this.state.emailOrPhoneMode;
      if (emailOrPhoneMode === "") {
        emailOrPhoneMode =
          signupItem.name === "Email or Phone" ? "Email" : "Phone";
        this.setState({emailOrPhoneMode: emailOrPhoneMode});
      }
      // const arrs = signupItem.name === "Email or Phone" ? ["Email", "Phone"] : ["Phone", "Email"];
      return (
        <>
          <div className="text-sm  font-medium text-secondary-700 mb-[6px]">
            {signupItem.label ? signupItem.label : i18next.t("signup:Username")}
          </div>
          {
            // application.signupItems.find(item => item.name === "Email or Phone") &&
            // <div className="flex w-full border my-5  border-secondary cursor-pointer h-11 rounded-lg bg-secondary-alt p-1 justify-center">
            //   {arrs.map((item) => {
            //     return <div key={item} onClick={() => {this.setState({emailOrPhoneMode: item});}} className={`font-semibold text-sm rounded-md  w-[195px] flex justify-center items-center ${this.state.emailOrPhoneMode === item ? "text-secondary-700 bg-white shadow-sm" : "text-quarterary-500 bg-transparent"}`}>{item}</div>;
            //   })}
            // </div>
            // <Row style={{marginTop: "20px", marginBottom: "20px"}} >
            //   <Radio.Group style={{width: "400px"}} buttonStyle="solid" onChange={e => {
            //     this.setState({
            //       emailOrPhoneMode: e.target.value,
            //     });
            //   }} value={emailOrPhoneMode}>
            //     {
            //       signupItem.name === "Email or Phone" ? (
            //         <React.Fragment>
            //           <Radio.Button value={"Email"}>{i18next.t("general:Email")}</Radio.Button>
            //           <Radio.Button value={"Phone"}>{i18next.t("general:Phone")}</Radio.Button>
            //         </React.Fragment>
            //       ) : (
            //         <React.Fragment>
            //           <Radio.Button value={"Phone"}>{i18next.t("general:Phone")}</Radio.Button>
            //           <Radio.Button value={"Email"}>{i18next.t("general:Email")}</Radio.Button>
            //         </React.Fragment>
            //       )
            //     }
            //   </Radio.Group>
            // </Row>
          }
          <Form.Item
            name="username"
            className="signup-username !mb-8 "
            // label={signupItem.label ? signupItem.label : i18next.t("signup:Username")}
            rules={[
              {
                required: required,
                message: i18next.t("forget:Please input your username!"),
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<></>}
              className="!rounded-lg signup-username-input focus:shadow-input placeholder"
              placeholder="Enter your name"
              disabled={
                this.state.invitation !== undefined &&
                this.state.invitation.username !== ""
              }
            />
          </Form.Item>
        </>
      );
    } else if (signupItem.name === "Display name") {
      if (signupItem.rule === "First, last" && Setting.getLanguage() !== "zh") {
        return (
          <React.Fragment>
            <Form.Item
              name="firstName"
              className="signup-first-name"
              label={
                signupItem.label
                  ? signupItem.label
                  : i18next.t("general:First name")
              }
              rules={[
                {
                  required: required,
                  message: i18next.t("signup:Please input your first name!"),
                  whitespace: true,
                },
              ]}
            >
              <Input
                className="signup-first-name-input"
                placeholder={signupItem.placeholder}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              className="signup-last-name"
              label={
                signupItem.label
                  ? signupItem.label
                  : i18next.t("general:Last name")
              }
              rules={[
                {
                  required: required,
                  message: i18next.t("signup:Please input your last name!"),
                  whitespace: true,
                },
              ]}
            >
              <Input
                className="signup-last-name-input"
                placeholder={signupItem.placeholder}
              />
            </Form.Item>
          </React.Fragment>
        );
      }

      return (
        <>
          <div className="text-sm font-medium text-secondary-700  mb-[6px]">
            {signupItem.label
              ? signupItem.label
              : i18next.t("general:Username")}
          </div>
          <Form.Item
            name="name"
            className="signup-name"
            // label={
            //   signupItem.label
            //     ? signupItem.label
            //     : signupItem.rule === "Real name" ||
            //       signupItem.rule === "First, last"
            //       ? i18next.t("general:Real name")
            //       : i18next.t("general:Display name")
            // }
            rules={[
              {
                required: required,
                message:
                  signupItem.rule === "Real name" ||
                  signupItem.rule === "First, last"
                    ? i18next.t("signup:Please input your real name!")
                    : i18next.t("signup:Please input your display name!"),
                whitespace: true,
              },
            ]}
          >
            <Input
              prefix={<></>}
              className="signup-name-input !rounded-lg placeholder"
              placeholder="Enter your name"
            />
          </Form.Item>
        </>
      );
    } else if (signupItem.name === "Affiliation") {
      return (
        <Form.Item
          name="affiliation"
          className="signup-affiliation"
          label={
            signupItem.label ? signupItem.label : i18next.t("user:Affiliation")
          }
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your affiliation!"),
              whitespace: true,
            },
          ]}
        >
          <Input
            className="signup-affiliation-input"
            placeholder={signupItem.placeholder}
          />
        </Form.Item>
      );
    } else if (signupItem.name === "ID card") {
      return (
        <Form.Item
          name="idCard"
          className="signup-idcard"
          label={
            signupItem.label ? signupItem.label : i18next.t("user:ID card")
          }
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your ID card number!"),
              whitespace: true,
            },
            {
              required: required,
              pattern: new RegExp(
                /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/,
                "g"
              ),
              message: i18next.t(
                "signup:Please input the correct ID card number!"
              ),
            },
          ]}
        >
          <Input
            className="signup-idcard-input"
            placeholder={signupItem.placeholder}
          />
        </Form.Item>
      );
    } else if (signupItem.name === "Country/Region") {
      return (
        <Form.Item
          name="country_region"
          className="signup-country-region"
          label={
            signupItem.label
              ? signupItem.label
              : i18next.t("user:Country/Region")
          }
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please select your country/region!"),
            },
          ]}
        >
          <RegionSelect
            className="signup-region-select"
            onChange={(value) => {
              this.setState({region: value});
            }}
          />
        </Form.Item>
      );
    } else if (
      signupItem.name === "Email" ||
      signupItem.name === "Phone" ||
      signupItem.name === "Email or Phone" ||
      signupItem.name === "Phone or Email"
    ) {
      const renderEmailItem = () => {
        return (
          <React.Fragment>
            <div className="text-sm font-medium text-secondary-700  mb-[6px]">
              {signupItem.label ? signupItem.label : i18next.t("general:Email")}
            </div>
            <Form.Item
              name="email"
              className="signup-email  !mb-8"
              // label={signupItem.label ? signupItem.label : i18next.t("general:Email")}
              rules={[
                {
                  required: required,
                  message: i18next.t("signup:Please input your Email!"),
                },
                {
                  validator: (_, value) => {
                    if (
                      this.state.email !== "" &&
                      !Setting.isValidEmail(this.state.email)
                    ) {
                      this.setState({validEmail: false});
                      return Promise.reject(
                        i18next.t("signup:The input is not valid Email!")
                      );
                    }

                    if (signupItem.regex) {
                      const reg = new RegExp(signupItem.regex);
                      if (!reg.test(this.state.email)) {
                        this.setState({validEmail: false});
                        return Promise.reject(
                          i18next.t(
                            "signup:The input Email doesn't match the signup item regex!"
                          )
                        );
                      }
                    }

                    this.setState({validEmail: true});
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                prefix={<></>}
                className="signup-email-input !rounded-lg placeholder"
                placeholder="Enter your email"
                disabled={
                  this.state.invitation !== undefined &&
                  this.state.invitation.email !== ""
                }
                onChange={(e) => this.setState({email: e.target.value})}
              />
            </Form.Item>
            {signupItem.rule !== "No verification" && (
              <>
                {" "}
                <div className="text-sm font-medium text-secondary-700  mb-[6px]">
                  {signupItem.label
                    ? signupItem.label
                    : i18next.t("code:Email code")}
                </div>
                <Form.Item
                  name="emailCode"
                  className="signup-email-code  !mb-8"
                  // label={signupItem.label ? signupItem.label : i18next.t("code:Email code")}
                  rules={[
                    {
                      required: required,
                      message: i18next.t(
                        "code:Please input your verification code!"
                      ),
                    },
                  ]}
                >
                  <SendCodeInput
                    className="signup-email-code-input"
                    disabled={!this.state.validEmail}
                    method={"signup"}
                    onButtonClickArgs={[
                      this.state.email,
                      "email",
                      Setting.getApplicationName(application),
                    ]}
                    application={application}
                  />
                </Form.Item>
              </>
            )}
          </React.Fragment>
        );
      };

      const renderPhoneItem = () => {
        return (
          <React.Fragment>
            <Form.Item
              className="signup-phone   !mb-8"
              // label={signupItem.label ? signupItem.label : i18next.t("general:Phone")}
              required={required}
            >
              <div className="text-sm font-medium text-secondary-700 mb-[6px]">
                {signupItem.label
                  ? signupItem.label
                  : i18next.t("general:Phone")}
              </div>
              <Input.Group compact>
                <Form.Item
                  name="countryCode"
                  noStyle
                  rules={[
                    {
                      required: required,
                      message: i18next.t(
                        "signup:Please select your country code!"
                      ),
                    },
                  ]}
                >
                  <CountryCodeSelect
                    style={{width: "35%"}}
                    countryCodes={
                      this.getApplicationObj().organizationObj.countryCodes
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  dependencies={["countryCode"]}
                  noStyle
                  rules={[
                    {
                      required: required,
                      message: i18next.t(
                        "signup:Please input your phone number!"
                      ),
                    },
                    ({getFieldValue}) => ({
                      validator: (_, value) => {
                        if (!required && !value) {
                          return Promise.resolve();
                        }

                        if (
                          value &&
                          !Setting.isValidPhone(
                            value,
                            getFieldValue("countryCode")
                          )
                        ) {
                          this.setState({validPhone: false});
                          return Promise.reject(
                            i18next.t("signup:The input is not valid Phone!")
                          );
                        }

                        this.setState({validPhone: true});
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    prefix={<></>}
                    className="signup-phone-input !rounded-r-lg placeholder"
                    placeholder="Enter your phone"
                    style={{width: "65%"}}
                    disabled={
                      this.state.invitation !== undefined &&
                      this.state.invitation.phone !== ""
                    }
                    onChange={(e) => this.setState({phone: e.target.value})}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            {signupItem.rule !== "No verification" && (
              <Form.Item
                name="phoneCode"
                className="phone-code !mb-8"
                // label={signupItem.label ? signupItem.label : i18next.t("code:Phone code")}
                rules={[
                  {
                    required: required,
                    message: i18next.t(
                      "code:Please input your phone verification code!"
                    ),
                  },
                ]}
              >
                <div className="text-sm font-medium text-secondary-700 mb-[6px]">
                  {signupItem.label
                    ? signupItem.label
                    : i18next.t("code:Phone code")}
                </div>
                <SendCodeInput
                  className="signup-phone-code-input"
                  disabled={!this.state.validPhone}
                  method={"signup"}
                  onButtonClickArgs={[
                    this.state.phone,
                    "phone",
                    Setting.getApplicationName(application),
                  ]}
                  application={application}
                  countryCode={this.form.current?.getFieldValue("countryCode")}
                />
              </Form.Item>
            )}
            {application.signupItems.find(
              (item) => item.name === "Agreement"
            ) &&
              AgreementModal.renderAgreementFormItem(
                application,
                required,
                tailFormItemLayout,
                this
              )}
          </React.Fragment>
        );
      };

      if (signupItem.name === "Email") {
        return renderEmailItem();
      } else if (signupItem.name === "Phone") {
        return renderPhoneItem();
      } else if (
        signupItem.name === "Email or Phone" ||
        signupItem.name === "Phone or Email"
      ) {
        let emailOrPhoneMode = this.state.emailOrPhoneMode;
        if (emailOrPhoneMode === "") {
          emailOrPhoneMode =
            signupItem.name === "Email or Phone" ? "Email" : "Phone";
        }

        return (
          <React.Fragment>
            {emailOrPhoneMode === "Email"
              ? renderEmailItem()
              : renderPhoneItem()}
          </React.Fragment>
        );
      } else {
        return null;
      }
    } else if (signupItem.name === "Password") {
      return (
        <>
          <div className="text-sm font-medium text-secondary-700  mb-[6px]">
            {signupItem.label
              ? signupItem.label
              : i18next.t("general:Password")}
          </div>
          <Form.Item
            name="password"
            className="signup-password  !mb-8"
            // label={signupItem.label ? signupItem.label : i18next.t("general:Password")}
            rules={[
              {
                required: required,
                validateTrigger: "onChange",
                validator: (rule, value) => {
                  const errorMsg = PasswordChecker.checkPasswordComplexity(
                    value,
                    application.organizationObj.passwordOptions
                  );
                  if (errorMsg === "") {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(errorMsg);
                  }
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password
              iconRender={() => (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="eye-off">
                    <path
                      id="Icon"
                      d="M7.16196 3.39488C7.4329 3.35482 7.7124 3.33333 8.00028 3.33333C11.4036 3.33333 13.6369 6.33656 14.3871 7.52455C14.4779 7.66833 14.5233 7.74023 14.5488 7.85112C14.5678 7.93439 14.5678 8.06578 14.5487 8.14905C14.5233 8.25993 14.4776 8.3323 14.3861 8.47705C14.1862 8.79343 13.8814 9.23807 13.4777 9.7203M4.48288 4.47669C3.0415 5.45447 2.06297 6.81292 1.61407 7.52352C1.52286 7.66791 1.47725 7.74011 1.45183 7.85099C1.43273 7.93426 1.43272 8.06563 1.45181 8.14891C1.47722 8.25979 1.52262 8.33168 1.61342 8.47545C2.36369 9.66344 4.59694 12.6667 8.00028 12.6667C9.37255 12.6667 10.5546 12.1784 11.5259 11.5177M2.00028 2L14.0003 14M6.58606 6.58579C6.22413 6.94772 6.00028 7.44772 6.00028 8C6.00028 9.10457 6.89571 10 8.00028 10C8.55256 10 9.05256 9.77614 9.41449 9.41421"
                      stroke="#99B398"
                      strokeWidth="1.33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              )}
              className="signup-password-input placeholder"
              placeholder="Enter your password"
            />
          </Form.Item>
          {application.signupItems.find((item) => item.name === "Agreement") &&
            AgreementModal.renderAgreementFormItem(
              application,
              required,
              tailFormItemLayout,
              this
            )}
        </>
      );
    } else if (signupItem.name === "Confirm password") {
      return (
        <Form.Item
          name="confirm"
          className="signup-confirm"
          label={
            signupItem.label ? signupItem.label : i18next.t("signup:Confirm")
          }
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please confirm your password!"),
            },
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  i18next.t(
                    "signup:Your confirmed password is inconsistent with the password!"
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder={signupItem.placeholder} />
        </Form.Item>
      );
    } else if (signupItem.name === "Invitation code") {
      return (
        <Form.Item
          name="invitationCode"
          className="signup-invitation-code"
          label={
            signupItem.label
              ? signupItem.label
              : i18next.t("application:Invitation code")
          }
          rules={[
            {
              required: required,
              message: i18next.t("signup:Please input your invitation code!"),
            },
          ]}
        >
          <Input
            className="signup-invitation-code-input"
            placeholder={signupItem.placeholder}
            disabled={
              this.state.invitation !== undefined &&
              this.state.invitation !== ""
            }
          />
        </Form.Item>
      );
    } else if (signupItem.name.startsWith("Text ")) {
      return <div dangerouslySetInnerHTML={{__html: signupItem.label}} />;
    } else if (signupItem.name === "Signup button") {
      const showForm =
        Setting.isPasswordEnabled(application) ||
        Setting.isCodeSigninEnabled(application) ||
        Setting.isWebAuthnEnabled(application) ||
        Setting.isLdapEnabled(application);
      if (signupItem.rule === "None" || signupItem.rule === "") {
        signupItem.rule = showForm ? "small" : "big";
      }
      return (
        <>
          <Form.Item {...tailFormItemLayout} className="!mb-0">
            <Button
              type="primary"
              htmlType="submit"
              className="signup-button  text-base font-semibold"
            >
              {i18next.t("account:Continue")}
            </Button>
          </Form.Item>
          <div className="w-full h-px mt-7 border-t"></div>
          <div className="text-center -mt-2 font-normal text-xs text-disabled bg-white w-7 mx-auto">
            {" "}
            OR
          </div>

          {application.signupItems.find(
            (item) => item.name === "Providers"
          ) && (
            <div className="border h-11 rounded-lg py-px gap-3 mt-5">
              {application.providers
                .filter((providerItem) => this.isProviderVisible(providerItem))
                .map((providerItem) => {
                  return ProviderButton.renderProviderLogo(
                    providerItem.provider,
                    application,
                    null,
                    null,
                    signupItem.rule,
                    this.props.location
                  );
                })}
            </div>
          )}

          <div className="mt-5">
            &nbsp;&nbsp;{i18next.t("signup:Have account?")}&nbsp;
            <a
              className="signup-link font-semibold"
              onClick={() => {
                const linkInStorage = sessionStorage.getItem("signinUrl");
                if (linkInStorage !== null && linkInStorage !== "") {
                  Setting.goToLinkSoft(this, linkInStorage);
                } else {
                  Setting.redirectToLoginPage(application, this.props.history);
                }
              }}
            >
              &nbsp;{i18next.t("signup:Sign in now")}
            </a>
          </div>
          <div>
            &nbsp;&nbsp;Need help?&nbsp;
            <a className=" font-semibold">Contact us</a>
          </div>
        </>
      );
    } else if (signupItem.name === "Providers") {
      // const showForm = Setting.isPasswordEnabled(application) || Setting.isCodeSigninEnabled(application) || Setting.isWebAuthnEnabled(application) || Setting.isLdapEnabled(application);
      // if (signupItem.rule === "None" || signupItem.rule === "") {
      //   signupItem.rule = showForm ? "small" : "big";
      // }
      // return (
      //   application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
      //     return ProviderButton.renderProviderLogo(providerItem.provider, application, null, null, signupItem.rule, this.props.location);
      //   })
      // );
    }
  }

  renderForm(application) {
    if (!application.enableSignUp) {
      return (
        <Result
          status="error"
          title={i18next.t("application:Sign Up Error")}
          subTitle={i18next.t(
            "application:The application does not allow to sign up new account"
          )}
          extra={[
            <Button
              type="primary"
              key="signin"
              onClick={() =>
                Setting.redirectToLoginPage(application, this.props.history)
              }
            >
              {i18next.t("login:Sign In")}
            </Button>,
          ]}
        ></Result>
      );
    }
    if (this.state.invitation !== undefined) {
      if (this.state.invitation.username !== "") {
        this.form.current?.setFieldValue(
          "username",
          this.state.invitation.username
        );
      }
      if (this.state.invitation.email !== "") {
        this.form.current?.setFieldValue("email", this.state.invitation.email);
      }
      if (this.state.invitation.phone !== "") {
        this.form.current?.setFieldValue("phone", this.state.invitation.phone);
      }
      if (this.state.invitationCode !== "") {
        this.form.current?.setFieldValue(
          "invitationCode",
          this.state.invitationCode
        );
      }
    }
    return (
      <Form
        {...formItemLayout}
        ref={this.form}
        name="signup"
        onFinish={(values) => this.onFinish(values)}
        onFinishFailed={(errorInfo) =>
          this.onFinishFailed(
            errorInfo.values,
            errorInfo.errorFields,
            errorInfo.outOfDate
          )
        }
        initialValues={{
          application: application.name,
          organization: application.organization,
          countryCode: application.organizationObj.countryCodes?.[0],
        }}
        size="large"
        layout={Setting.isMobile() ? "vertical" : "horizontal"}
        style={{
          width: Setting.isMobile() ? "300px" : "352px",
          marginTop: "20px",
        }}
      >
        <Form.Item
          name="application"
          hidden={true}
          rules={[
            {
              required: true,
              message: "Please input your application!",
            },
          ]}
        ></Form.Item>
        <Form.Item
          name="organization"
          hidden={true}
          rules={[
            {
              required: true,
              message: "Please input your organization!",
            },
          ]}
        ></Form.Item>
        {application.signupItems?.map((signupItem, idx) => {
          return (
            <div key={idx}>
              <div
                dangerouslySetInnerHTML={{
                  __html: "<style>" + signupItem.customCss + "</style>",
                }}
              />
              {this.renderFormItem(application, signupItem)}
            </div>
          );
        })}
      </Form>
    );
  }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined || application === null) {
      return null;
    }

    let existSignupButton = false;
    application.signupItems?.map((item) => {
      item.name === "Signup button" ? (existSignupButton = true) : null;
    });
    if (!existSignupButton) {
      application.signupItems?.push({
        customCss: "",
        label: "",
        name: "Signup button",
        placeholder: "",
        visible: true,
      });
    }

    if (application.signupHtml !== "") {
      return (
        <div dangerouslySetInnerHTML={{__html: application.signupHtml}} />
      );
    }
    return (
      <React.Fragment>
        <CustomGithubCorner />
        <div
          className="login-content"
          style={{
            margin:
              this.props.preview ?? this.parseOffset(application.formOffset),
          }}
        >
          {Setting.inIframe() || Setting.isMobile() ? null : (
            <div dangerouslySetInnerHTML={{__html: application.formCss}} />
          )}
          {Setting.inIframe() || !Setting.isMobile() ? null : (
            <div
              dangerouslySetInnerHTML={{__html: application.formCssMobile}}
            />
          )}
          <div className="login-panel">
            <div
              className="side-image"
              style={{display: application.formOffset !== 4 ? "none" : null}}
            >
              <div
                dangerouslySetInnerHTML={{__html: application.formSideHtml}}
              />
            </div>
            <div className="login-form">
              {Setting.renderHelmet(application)}
              <div className="flex flex-col gap-4">
                {Setting.renderLogo(application)}
                <div className="text-lg font-semibold">Sign up</div>
              </div>
              {/* <LanguageSelect languages={application.organizationObj.languages} style={{top: "55px", right: "5px", position: "absolute"}} /> */}
              {this.renderForm(application)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SignupPage);
